import React, { useEffect, useMemo, useState } from 'react'
import { Link, navigate } from 'gatsby';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { Button, Eye, IconButton, Pencil, Refresh } from 'nzk-react-components';
import FETCH_ASSIGNMENT_TEMPLATES from '../data/AssignmentTemplate/graphql/getAssignmentTemplates.graphql'
import Table from '../../../TableV2';

const Wrapper = styled.div``

const Header = styled.div`
  display: flex;
  margin-bottom: 10px;
  > * { margin-right: 10px; }
`

const Actions = styled.div`
  display: flex;
  justify-content: flex-start;
  > * { margin-right: 10px; }
  > :last-child { margin-right: 0; }
`

const AssignmentTemplatesPage = () => {
  const query = new URLSearchParams(location.search)
  console.log(query.get('page'))
  const [pageSize, setPageSize] = useState(5)
  const [page, setPage] = useState(0)
  const [maxPage, setMaxPage] = useState(2)

  const { data, loading, fetchMore } = useQuery(FETCH_ASSIGNMENT_TEMPLATES, {
    variables: {
      skip: page * pageSize,
      limit: pageSize
    },
    fetchPolicy: query.get('refetch') === 'true' ? 'network-only' : undefined
  })

  const assignmentTemplates = data?.starTutoring_assignmentTemplates || []

  const fetchPage = async ({ skip, limit }: { skip: number, limit: number }) => {
    setPage(skip / limit)
    setPageSize(limit)
    const { data } = await fetchMore({ variables: { skip, limit } })
    if ((data?.starTutoring_assignmentTemplates || []).length >= limit) {
      setMaxPage(m => Math.max(skip / limit + 2, m))
    }
  }

  const formattedTemplates = useMemo(() => {
    return assignmentTemplates.filter(d => d).map((a: any) => ({
      ...a,
      type: a.writingPrompt ? 'Writing 📝' : 'Lesson 📘'
    }))
  }, [assignmentTemplates])

  const columns = useMemo(() => {
    return [
      {
        'Header': 'Title',
        'accessor': 'title',
        disableFilters: true,
        // TODO: Add when page is good enough
        // Cell: ({ row }: any) => <Link to={`/star-tutoring/assignment-templates/${row.original._id}`}>{row.original.title}</Link>
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }: any) => <Link to={`/star-tutoring/assignment-templates/${row.original._id}`}>{row.original.title}</Link>
      },
      {
        'Header': 'Type',
        'accessor': 'type',
        disableFilters: true,
      },
      {
        'Header': 'ID',
        'accessor': '_id',
        disableFilters: true,
        // TODO: Add when page is good enough
        // Cell: ({ row }: any) => <Link to={`/star-tutoring/assignment-templates/${row.original._id}`}>{row.original.title}</Link>
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }: any) => <Link to={`/star-tutoring/assignment-templates/${row.original._id}/edit`}>{row.original._id}</Link>
      },
      {
        'Header': 'Actions',
        disableFilters: true,
        // TODO: Add when page is good enough
        // Cell: ({ row }: any) => <Link to={`/star-tutoring/assignment-templates/${row.original._id}`}>{row.original.title}</Link>
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }: any) => <Actions>
          <Link to={`/star-tutoring/assignment-templates/${row.original._id}`}>
            <IconButton icon={<Eye />} size='x-small' theme='primary'>More</IconButton>
          </Link>
          <Link to={`/star-tutoring/assignment-templates/${row.original._id}/edit`}>
            <IconButton icon={<Pencil />} size='x-small' theme='orange'>Edit</IconButton>
          </Link>
        </Actions>
      },
    ]
  }, [formattedTemplates])

  return <Wrapper>
    <Header>
      <IconButton size='x-small' theme='primary' icon={<Refresh />} onClick={() => {
        fetchPage({ skip: page * pageSize, limit: pageSize })
      }}>Refresh</IconButton>
      <Button size='x-small' theme='confirm' onClick={() => navigate('/star-tutoring/assignment-templates/create')}>Create</Button>
    </Header>
    <Table columns={columns} data={formattedTemplates} tableOptions={{
      manualPagination: true
    }} loading={loading} fetchPage={fetchPage} pageCount={maxPage} />
  </Wrapper>
}

export default AssignmentTemplatesPage
