import React from 'react'
import AssignmentTemplatesPage from '../../../components/pages/StarTutoring/AssignmentTemplatesPage'
import StarTutoringLayout from '../../../components/pages/StarTutoring/StarTutoringLayout'

const AssignmentTemplates = (props: any) => {
  return <StarTutoringLayout path={props.path}>
    <AssignmentTemplatesPage />
  </StarTutoringLayout>
}

export default AssignmentTemplates
